<template>
    <div>
        <section id="snippet-11" class="wrapper bg-light wrapper-border">
            <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
                <div class="row">
                    <!--/column -->
                    <div class="col-lg-12">
                        <h2 class="display-5 mb-10">Panduan OSS</h2>
                        <div class="table-responsive container">
                            <table class="table table-bordered" width="100%">
                                <thead class="bg-pale-blue">
                                    <tr>
                                        <th class="text-center" width="5%">No</th>
                                        <th class="text-center" width="40%">Uraian</th>
                                        <th class="text-center" width="7%">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, index ) in list_data" :key="data.id">
                                        <th class="text-center" scope="row">{{index+1}}</th>
                                        <!-- <th class="text-center" scope="row">{{indexing(index+1)}}</th> -->
                                        <td>{{ data.judul }}</td>
                                        <td class="text-center">
                                            <a @click="selectData(data)" href="javascript:void(0);" class="btn btn-sm btn-primary rounded-pill mx-1 mb-2 mb-md-0" data-bs-toggle="modal" data-bs-target="#modal-signin">
                                                <!-- <i class="uil uil-arrow-left"></i> -->
                                                Lihat
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="modal fade" id="modal-signin" tabindex="-1">
                                <div class="modal-dialog modal-lg modal-dialog-centered modal-sm">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            <!-- <p v-html="form.uraian"></p> -->
                                            <iframe :src="file_path+form.file" width="100%" height="800pxx"></iframe>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Keluar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <button @click="btn_prev" type="button" class="btn btn-primary btn-sm rounded-pill" id="pref">
                        <i class="uil uil-arrow-left"></i>
                        </button>
                        &nbsp;
                        <span style="font-size: 85%; color: #a5a5a5">
                            <b>{{page_first}} - {{page_last}}</b>
                        </span>
                        &nbsp;
                        <button @click="btn_next" type="button" class="btn btn-primary btn-sm rounded-pill" id="next">
                        <i class="uil uil-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

const ADD_URL = URL_APP + "api/v1/publish_dpmptsp_oss_rba/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load : false,
            form : {
                id : '',                
                judul : '',
                sumber : '',
                deskripsi : '',
                uraian : '',
                foto : '',
                unit_kerja : '',
                status : '',
                keterangan : '',
            },
            id :'',
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
            kategori: "Panduan OSS",
        }
    },
    methods: {
    getView: function() {
      fetch(ADD_URL + "view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data_ke: this.page_first,
          cari_value: this.cari_value,
          kategori: this.kategori
        })
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
        });
    },

    selectData: function(data) {
        this.form = {
            id : data.id,
            // kategori : data.kategori,
            judul : data.judul,
            file : data.file,
        };
    },

    mdl_exit: function() {
      this.judul = "";
      this.uraian = "";
      this.file = "";
      this.file_old = "";
    },

    //   ====================== MODAL ======================

        buka_modal: function(modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "block";
        },

        tutup_modal: function(modalku) {
            var modal = document.getElementById(modalku);
            modal.style.display = "none";
    },


    //   ====================== MODAL ======================
    // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
  // ================== PAGINASI ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }
};
</script>
